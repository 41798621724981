<template>
  <div class="ai-prompts-table">
    <v-simple-table>
      <thead>
        <tr class="ai-prompts-table__header">
          <th>Prompt Key</th>
          <th>Date Created</th>
          <th>Is Overridden?</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in aiPrompts" :key="i">
          <td class="ai-prompts-table__header">
            {{ item.promptKey }}
          </td>
          <td class="ai-prompts-table__text">
            <div :title="getDateWithDayNameAndTime(item.createdAt)">
              {{ getDate(item.createdAt) }}
            </div>
          </td>
          <td class="ai-prompts-table__text">
            <v-checkbox v-model="item.isOverridden" disabled />
          </td>
          <td>
            <v-btn
              class="elevation-0 outlined-btn px-lg-10 px-xl-15 ml-5 ml-lg-10 ml-xl-20"
              height="34px"
              plain
              @click="$emit('override', item)"
            >
              <v-icon size="12" color="#2b84eb">
                mdi-pencil
              </v-icon>
              Override/Test
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "AIPromptTable",
  components: {},
  mixins: [datesMixin],
  props: {
    aiPrompts: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.ai-prompts-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr {
      height: 77px !important;
      td {
        .simple-text-button {
          font-weight: 400;
        }
        border-bottom: solid 1px #dee7ee;
      }
    }
    tr:hover {
      background: inherit !important;
    }
  }
}
</style>
