<template>
  <v-card
    :class="{'popup-card': true, 'overridden': isOverridden}"
  >
    <v-card-title class="px-6 pt-4 pb-4">
      Override/Test Prompt
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <h3>Default Values</h3>
      <v-row>
        <v-col cols="6">
          <div class="mt-2">
            <strong>Prompt Key:</strong> {{ promptKey }}
          </div>
          <div class="mt-2">
            <strong>Default Provider:</strong>
            <template v-if="getProviderImage(defaultProvider)">
              <v-icon class="ml-2 mr-1">
                {{ getProviderImage(defaultProvider) }}
              </v-icon>
            </template>
            {{ getProviderName(defaultProvider) }}
          </div>
          <div class="mt-2">
            <strong>Default Prompt:</strong><br>
            <CustomTextareaV2
              :value="defaultPrompt"
              disabled
            />
          </div>
        </v-col>
        <v-col cols="6" class="mt-2">
          <strong>Default Prompt Options:</strong>
          <AIPromptOptions
            :provider="defaultProvider"
            :prompt-options="defaultPromptOptions"
            display-only
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="isOverridden"
            label="Override this prompt for this account"
            hide-details
            class="mt-2"
            @change="(val) => val === true ? onPromptChange(overridePrompt || defaultPrompt) : null"
          />
        </v-col>
      </v-row>
      <hr v-if="isOverridden" class="my-4">
      <v-row v-if="isOverridden">
        <v-col cols="6">
          <h3>Override Values</h3>

          <validation-observer ref="textInput">
            <CustomDropdown
              :value="overrideProvider"
              header="Override the AI Provider"
              :items="providers"
              placeholder="Override the AI Provider"
              class="mt-3"
              required
              autocomplete
              @input="(val) => overrideProvider = val"
            />
            <CustomTextareaV2
              header="Override the AI Prompt"
              placeholder="Enter the AI Prompt here"
              :value="overridePrompt || defaultPrompt"
              required
              @change="onPromptChange"
            />

            Override Prompt Options:
            <AIPromptOptions
              :provider="overrideProvider"
              :prompt-options="overridePromptOptions"
              @change="val => overridePromptOptions = val"
            />
          </validation-observer>
        </v-col>
        <v-col cols="6">
          <h3 class="mb-3">
            Test Override
          </h3>

          <div v-if="hasOverrideVariables">
            <strong>Enter values for variables</strong><br>
            <div
              v-for="(metadata, variable) in overrideVariables"
              :key="variable"
            >
              <CustomTextInput
                v-model="metadata.sampleData"
                :header="variable"
                :required="metadata.required"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <v-btn
              class="custom-button custom-button--blue px-15"
              height="34px"
              depressed
              :disabled="testPromptLoading"
              @click="testPrompt"
            >
              Test
            </v-btn>
          </div>

          <div class="ai-response mt-3">
            <AIPromptDisplay
              :is-loading="testPromptLoading"
              :loading-width="450"
              :response="testPromptResponse"
              :response-type="testResponseType"
            />
          </div>

          <div v-if="tokenCount" class="mt-3">
            Tokens used to respond: {{ tokenCount }}
          </div>
        </v-col>
      </v-row>


      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="savePrompt"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomTextareaV2 from "@/sharedComponents/CustomTextareaV2";
import AIPromptOptions from "./AIPromptOptions.vue";
import AIPromptDisplay from "./AIPromptDisplay.vue";

export default {
  name: "AIPromptOverride",
  components: {
    AIPromptOptions,
    AIPromptDisplay,
    CustomDropdown,
    CustomTextInput,
    CustomTextareaV2
  },
  props:{
    prompt:{
      default: () => ({})
    },
  },
  data(){
    return {
      id: "",
      promptKey: "",
      defaultProvider: "",
      defaultPrompt: "",
      defaultPromptOptions: {},
      defaultVariables: {},
      isOverridden: false,
      overrideProvider: 'openai',
      overridePrompt: null,
      overridePromptOptions: {
        model: 'gpt-4o-mini',
        temperature: 0.7,
        topP: 0.0,
        maxTokens: 50,
        responseType: 'text',
      },
      overrideVariables: {},
      providers: [
        { value: 'openai', label: 'OpenAI / ChatGPT', icon: '$openAI' }
      ],
      testPromptLoading: false,
      testPromptResponse: {},
      testResponseType: 'text',
      tokenCount: 0,
    }
  },
  computed: {
    hasOverrideVariables() {
      return Object.keys(this.overrideVariables).length > 0;
    },
  },
  created(){
    this.id = this.prompt.id;
    this.promptKey = this.prompt.promptKey;
    this.defaultProvider = this.prompt.defaultProvider;
    this.defaultPrompt = this.prompt.defaultPrompt;
    this.defaultPromptOptions = Array.isArray(this.prompt.defaultPromptOptions) ? {} : this.prompt.defaultPromptOptions;
    this.defaultVariables = Array.isArray(this.prompt.defaultVariables) ? {} : this.prompt.defaultVariables;
    this.isOverridden = this.prompt.isOverridden;
    this.overrideProvider = this.prompt.overrideProvider ?? this.overrideProvider;
    this.overridePrompt = this.prompt.overridePrompt;
    this.overridePromptOptions = Array.isArray(this.prompt.overridePromptOptions) ? {...this.prompt.defaultPromptOptions} : this.prompt.overridePromptOptions;
    this.overrideVariables = Array.isArray(this.prompt.overrideVariables) ? {...this.prompt.defaultVariables} : this.prompt.overrideVariables;
  },
  methods: {
    onPromptChange(val) {
      this.overridePrompt = val;

      const variables = this.detectVariables(this.overridePrompt);

      const overrideVariables = {};
      variables.forEach(v => {
        overrideVariables[v] = {
          required: false,
          sampleData: "",
          ...(this.defaultVariables[v] ?? {}),
          ...(this.overrideVariables[v] ?? {})
        };
      });

      this.overrideVariables = overrideVariables;
    },
    detectVariables(prompt) {
      if (!prompt) {
        return [];
      }

      return prompt.match(/{{\s*[\w.]+\s*}}/g)
        .map(function(x) { return x.match(/[\w.]+/)[0]; });
    },
    async testPrompt() {
      this.tokenCount = 0;
      this.testPromptLoading = true;
      this.testResponseType = this.overridePromptOptions?.responseType ?? 'text';

      const overrideVariables = {};
      Object.keys(this.overrideVariables).forEach(v => {
        overrideVariables[v] = this.overrideVariables[v]?.sampleData ?? "";
      });

      try {
        const resp = await this.$rest.ai.testPrompt({
          provider: this.overrideProvider,
          prompt: this.overridePrompt,
          promptOptions: this.overridePromptOptions,
          inputValues: overrideVariables,
        }, {
          hide_loader: true,
          hide_error: true,
        });

        this.testPromptResponse = resp.data;
        this.testPromptLoading = false;
        this.tokenCount = resp.data?.usage?.completion_tokens ?? 0;
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          let errors = '';

          if (errorResponse?.errors) {
            errorResponse.errors.forEach(item => {
              errors += (item.error + "\r \n");
            });
          } else if (errorResponse.message) {
            errors = errorResponse.message;
          }

          this.testResponseType = 'error';
          this.testPromptResponse = { response: errors };
          this.testPromptLoading = false;
        }
      }
    },
    async savePrompt(){
      if (this.$refs.textInput && !await this.$refs.textInput.validate()) {
        return;
      }

      let body = {};
      if (this.isOverridden) {
        body.isOverridden = this.isOverridden;
        body.overrideProvider = this.overrideProvider;
        body.overridePrompt = this.overridePrompt;
        body.overridePromptOptions = this.overridePromptOptions;
        body.overrideVariables = this.overrideVariables;
      } else {
        body.isOverridden = this.isOverridden;
        body.overrideProvider = null;
        body.overridePrompt = null;
        body.overridePromptOptions = {};
        body.overrideVariables = {};
      }

      this.$emit('save', {
        id: this.id,
        ...body
      });
    },
    getProviderImage(providerValue) {
      return this.providers.find(p => p.value === providerValue)?.icon;
    },
    getProviderName(providerValue) {
      return this.providers.find(p => p.value === providerValue)?.label ?? 'N/A';
    }
  },
}
</script>

<style lang="scss" scoped>
.popup-card {
  width: 1160px;

  .v-input--checkbox {
    margin-top: 4px;

    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      color: #241c15;
    }
    .v-icon {
      font-size: 18px;
    }
    .v-messages {
      display: none;
    }
  }
}
</style>

