<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      class="mb-7 px-3"
    >
      <template #header>
        <img
          src="@/assets/icons/pulse_ai_icon.png"
          alt="pulse ai icon"
          height="32"
        >
        Pulse AI Prompts
      </template>
      <template #subheader>
        Manage all of your AI prompts
      </template>
    </PageHeader>

    <div v-if="$vuetify.breakpoint.xsOnly" class="mobile-hint mb-5 px-3">
      To override an AI prompt for this account, please view on desktop
    </div>

    <AIPromptTable
      :ai-prompts="aiPrompts"
      class="px-3"
      @override="openOverrideDialog"
    />

    <v-dialog
      v-model="overrideDialog"
      scrollable
      max-width="1100px"
      width="unset"
      @click:outside="promptToOverride = {}"
    >
      <AIPromptOverride
        v-if="overrideDialog"
        :prompt="promptToOverride"
        @save="savePrompt"
        @dismiss="overrideDialog = false; promptToOverride = {}"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import AIPromptTable from "@/views/AI/components/AIPromptTable";
import AIPromptOverride from "@/views/AI/components/AIPromptOverride";
import store from "../../store";

export default {
  name: "AIPromptListView",
  metaInfo: {
    title: 'AI Prompts'
  },
  components: {
    PageHeader,
    AIPromptTable,
    AIPromptOverride,
  },
  data() {
    return {
      overrideDialog: false,
      promptToOverride: {},
      aiPrompts: [],
    };
  },
  created() {
    this.loadAIPrompts();
  },
  methods: {
    async loadAIPrompts() {
      const resp = await this.$rest.ai.getPromptsCollection({});
      this.aiPrompts = resp.data.items;
    },
    async savePrompt(prompt) {
      try {
        await this.$rest.ai.updatePrompt(prompt.id, prompt);

        await this.loadAIPrompts();

        this.overrideDialog = false;
        store.commit("snackbar/showMessage", {
          content: "The prompt was successfully updated.",
          color: "success"
        });
      } catch (e) {
        let errorMessage = "";
        if (error.response?.data?.errors?.length) {
          error.response.data.errors.forEach(item => {
            errorMessage += (item.error + "\r \n");
          });
        } else if (error.response?.data?.message) {
          errorMessage = error.response?.data?.message;
        }

        store.commit("snackbar/showMessage", {
          content: errorMessage || "Something went wrong",
          color: "error"
        });
      }
    },
    openOverrideDialog(prompt) {
      this.promptToOverride = prompt;
      this.overrideDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}
</style>
