<template>
  <div class="ai-prompt-options">
    <div v-if="displayOnly" class="mt-2">
      <div>
        <div class="mt-2">
          <strong>Model:</strong> {{ aiPromptOptions.model || 'gpt-4o-mini' }}
        </div>
        <div class="mt-2">
          <strong>Temperature:</strong> {{ aiPromptOptions.temperature || 0.0 }}
        </div>
        <div class="mt-2">
          <strong>TopP:</strong> {{ aiPromptOptions.topP || 0.0 }}
        </div>
        <div class="mt-2">
          <strong>Max Tokens:</strong> {{ aiPromptOptions.maxTokens || 50 }}
        </div>
        <div class="mt-2">
          <strong>Response Type:</strong> {{ aiPromptOptions.responseType || 'text' }}
        </div>
      </div>
    </div>
    <div v-else>
      <CustomTextInput
        v-model="aiPromptOptions.model"
        header="Model"
        required
        @change="val => onInputChange('model', val)"
      />
      <CustomTextInput
        v-model="aiPromptOptions.temperature"
        header="Temperature"
        type="number"
        required
        :min-value="0.0"
        :max-value="1.0"
        @change="val => onInputChange('temperature', val)"
      />
      <CustomTextInput
        v-model="aiPromptOptions.topP"
        header="TopP"
        type="number"
        required
        :min-value="0.0"
        :max-value="1.0"
        @change="val => onInputChange('topP', val)"
      />
      <CustomTextInput
        v-model="aiPromptOptions.maxTokens"
        header="Max Tokens"
        type="number"
        required
        @change="val => onInputChange('maxTokens', val)"
      />
      <CustomDropdown
        :value="aiPromptOptions.responseType"
        header="Response Type"
        :items="responseTypes"
        required
        @change="val => onInputChange('responseType', val)"
      />
    </div>
  </div>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "AIPromptOptions",
  components: {
    CustomTextInput,
    CustomDropdown,
  },
  props:{
    provider: {
      default: '',
      type: String,
    },
    displayOnly: {
      default: false,
      type: Boolean,
    },
    promptOptions: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      aiPromptOptions: {
        model: 'gpt-4o-mini',
        temperature: 0.0,
        topP: 0.0,
        maxTokens: 50,
        responseType: 'text',
      },
      responseTypes: [
        { label: 'Text', value: 'text' },
        { label: 'List', value: 'list' },
        { label: 'JSON', value: 'json' },
      ],
    }
  },
  created() {
    this.aiPromptOptions = {...this.aiPromptOptions, ...this.promptOptions};
  },
  methods: {
    onInputChange(key, value) {
      this.aiPromptOptions[key] = value;

      this.$emit('change', {
        ...this.aiPromptOptions,
        temperature: parseFloat(this.aiPromptOptions.temperature),
        topP: parseFloat(this.aiPromptOptions.topP),
        maxTokens: parseInt(this.aiPromptOptions.maxTokens),
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

